import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { CodeInput } from "../CodeInput/CodeInput";
import axios from "axios";
import { styles as renderStyles } from "./styles";
import config from "../../config";

import * as namespace from "../../lang/namespace";

import spinner from "../../Assets/Eclipse.png";
import "./animations.css";
import "./styles.css";
import { InBrowserLaunchState } from "./InBrowserLaunchState";
import { InBrowserLaunchProps } from "./InBrowserLaunchProps";
import { isLinuxOS, webRTCSupported } from "../../Functions/webRTCSupported";

export class InBrowserLaunch extends React.Component<
  InBrowserLaunchProps,
  InBrowserLaunchState
> {
  nameFieldRef = null;
  submitButtonRef = null;
  connection: RTCPeerConnection;
  ws: WebSocket;

  constructor(props) {
    super(props);
    this.state = {
      view: "loading", // ENUM: "loading" | "show_form" | "success" | "connecting"
      showScreenKey: true,
      showShareButton: false,
      screenKey: "",
      hasErrored: false,
      invalidKey: false,
      displayName: ""
    };
  }

  // View State Logic
  success = () => {
    this.setState({ view: "success" });
  };

  showForm = () => {
    this.setState({ view: "show_form", displayName: "" });
  };

  showError = () => {
    this.setState({ view: "show_form", displayName: "", hasErrored: true });
  };

  generateWSUrl = () => {
    var platform = require("platform");

    const { screenKey, displayName } = this.state;
    const { hostname, port } = window.location;

    var operating_system = platform.os.family;

    // Do a check for various Linux operating systems and take the string to
    // just "Linux". The `platform` library will often return distro names like
    // Ubuntu, Fedora, etc. So this isn't an exhaustive check but it should
    // catch many of the common cases.
    if (isLinuxOS(operating_system)) {
      operating_system = "Linux";
    }

    const queryParams = {
      screen_key: screenKey,
      display_name: displayName,
      browser_name: platform.name + "%20" + platform.version,
      operating_system: operating_system
    };

    const serializedQueryParams = Object.keys(queryParams)
      .map(paramKey => `${paramKey}=${queryParams[paramKey]}`)
      .join("&");

    const ws_url =
      window.location.hostname === "localhost"
        ? `ws://localhost:4000?${serializedQueryParams}`
        : `wss://${hostname}${port ? `:${port}` : ""
        }/webrtc?${serializedQueryParams}`;

    return ws_url;
  };

  getLocalStream = () => {
    var constraints = {
      video: {
        mediaSource: "screen",
        width: {
          max: 1920
        },
        height: {
          max: 1080
        }
      },
      audio: true
    };

    var mediaDevicesConstraints = {
      video: {
        displaySurface: "monitor",
        height: {
          max: 1080
        },
        width: {
          max: 1920
        }
      },
      audio: true
    } as MediaStreamConstraints;

    try {
      const mediaDevices: any = navigator.mediaDevices;

      if (mediaDevices.getDisplayMedia) {
        return mediaDevices.getDisplayMedia(constraints);
      } else if (mediaDevices.getUserMedia) {
        return mediaDevices.getUserMedia(mediaDevicesConstraints);
      } else {
        console.error("unable to get media");
        return Promise.reject("unable to get media");
      }
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  finalizeRTC = (websocket, rtcConnection = null, stream = null) => {
    if (stream) {
      stream.getTracks().forEach(track => {
        if (track.readyState === "live") track.stop();
      });
    }

    if (
      rtcConnection &&
      (rtcConnection.connectionState === "new" ||
        rtcConnection.connectionState === "connected" ||
        rtcConnection.connectionState === "checking" ||
        rtcConnection.connectionState === "completed")
    ) {
      rtcConnection.close();
    }

    if (websocket && websocket.readyState === websocket.OPEN) {
      websocket.close();
    }
  };

  // Business Logic
  startCall = () => {
    this.setState({
      view: "connecting",
      invalidKey: false
    });

    try {
      this.connection = new RTCPeerConnection({
        iceServers: [
          {
            urls: "stun:stun.services.mozilla.com"
          },
          {
            urls: "stun:stun.l.google.com:19302"
          }
        ]
      });

      this.connection.onnegotiationneeded = event => {
        console.log("OnNegotiatedNeeded: ", event);
      };

      console.log("Opening websocket connection...");
      try {
        this.ws = new WebSocket(this.generateWSUrl());

        this.ws.onerror = (errorEvent: Event) => {
          console.log(
            "There was an error in the signaling server websocket: ",
            errorEvent
          );
          this.finalizeRTC(this.ws, this.connection, null);
          this.showError();
        };

        this.ws.onmessage = event => {
          let message = {};

          try {
            message = JSON.parse(event.data);
          } catch (error) {
            console.log(error);
          }

          // console.log("receive message", message);

          if (message["auth"] === "invalid screen key") {
            console.log("Invalid screen key provided.");
            this.finalizeRTC(this.ws, this.connection, null);
            this.setState({ invalidKey: true });
            this.showForm();
            return;
          } else if (message["auth"] === "valid screen key") {
            this.success();
            this.startStream(false);
          } else if (message["type"] === "answer") {
            this.connection.setRemoteDescription(
              new RTCSessionDescription({
                type: "answer",
                sdp: message["sdp"]
              })
            );
          } else if (message["candidate"]) {
            console.log("Received candidate", message);
            this.connection.addIceCandidate(message).catch(e => {
              console.log("Error adding candidate", e.name);
            });
          } else {
            return;
          }
        };

        this.ws.onopen = _ => {
          this.connection.onicecandidate = event => {
            console.log("We're adding an ICE candidate");
            if (event.candidate) {
              this.ws.send(JSON.stringify(event.candidate));
            }
          };
        };

        this.ws.onclose = _ => {
          this.finalizeRTC(this.ws, this.connection, null);
        };
      } catch (err) {
        console.error(err);
        this.finalizeRTC(null, this.connection, null);
        this.showError();
      }
    } catch (err) {
      console.error(err);
      this.showError();
    }
  };

  startStream = (closeOnFailure) => {
    this.getLocalStream().then(stream => {
      console.log("Opening RTC Peer connection...");

      console.log("got stream", stream);

      stream.getVideoTracks()[0].onended = () => {
        console.log("stream ending, closing websocket");
        this.finalizeRTC(this.ws, this.connection, stream); // This may be redundant
      };

      window.addEventListener("unload", event => {
        this.finalizeRTC(this.ws, this.connection, stream);
      });

      for (const track of stream.getTracks()) {
        console.log("adding track", track);
        this.connection.addTrack(track, stream);
      }

      this.connection.createOffer().then(offer => {
        console.log("got offer", offer);

        this.connection
          .setLocalDescription(new RTCSessionDescription(offer))
          .catch(e => {
            console.log("Couldn't make local description");
          });

        console.log("send offer", JSON.stringify(offer));
        this.ws.send(JSON.stringify(offer));
      });
    })
      .catch(err => {
        console.error("Error while trying to get media stream: ", err);
        if (closeOnFailure) {
          this.finalizeRTC(this.ws, this.connection);
          this.showForm();
        }
        this.setState({ showShareButton: true });
      });
    this.setState({ showShareButton: false });
  }

  // Lifecycle hooks
  componentWillMount() {
    const url = config.ping;
    axios(url)
      .then(response => {
        const status = response.data;

        return status;
      })
      .then(screenKeyStatus => {
        this.setState(() => ({
          showScreenKey: screenKeyStatus !== "ScreenKeyDisabled",
          view: "show_form"
        }));
      })
      .catch(err => {
        // If there's a failure, then output the error and set the view to the form page with the screen key showing
        console.error(
          `Error trying to get the screen key configuration: ${err}`
        );

        this.showError();
      });
  }

  highlightNameField = () => {
    this.nameFieldRef.focus();
  };

  // Event Handlers
  handleNameFieldKeypress = event => {
    if (event.key === "Enter") {
      this.submitButtonRef.click();
    }
  };

  handleNameFieldChange = event => {
    const targetValue = event.target.value;

    this.setState(oldState => {
      return {
        ...oldState,
        displayName: targetValue
      };
    });
  };

  screenKeyValueChanged = screenKey => {
    this.setState(oldState => {
      return {
        ...oldState,
        screenKey
      };
    });
  };

  // Render
  render() {
    const {
      displayName,
      showScreenKey,
      showShareButton,
      hasErrored,
      invalidKey,
      screenKey,
      view
    } = this.state;

    const disableSubmitButton =
      (showScreenKey && screenKey.length < 4) || displayName === "";

    const styles = renderStyles(disableSubmitButton, showScreenKey);

    if (!webRTCSupported(require("platform"))) {
      return (
        <div style={styles.label}>Browser sharing is not supported for this browser</div>
      );
    }

    return (
      <div>
        {view === "show_form" && (
          <div style={styles.parent}>
            {showScreenKey && (
              <div>
                <label style={styles.label}>
                  <div style={styles.labelText}>
                    <Trans i18nKey="quickconnect__enterKey">
                      Enter key shown on room display
                    </Trans>
                  </div>
                </label>
                <CodeInput
                  digits={4}
                  finished={this.highlightNameField}
                  inputChanged={this.screenKeyValueChanged}
                  hasError={invalidKey}
                />
                {invalidKey && (
                  <span style={styles.error}>
                    <Trans i18nKey="quickconnect__invalidScreenKey">
                      Invalid screen key
                    </Trans>
                  </span>
                )}
              </div>
            )}
            <label style={styles.label}>
              <div
                style={{
                  ...styles.labelText,
                  marginTop: showScreenKey ? "1.375rem" : null
                }}
              >
                <Trans i18nKey="quickconnect__yourName">
                  Your name
                </Trans>
              </div>
              <input
                style={styles.input}
                type="text"
                name="Display Name"
                ref={ref => (this.nameFieldRef = ref)}
                className="enter"
                id="display_name"
                value={displayName}
                onChange={this.handleNameFieldChange}
                onKeyPress={this.handleNameFieldKeypress}
              />
            </label>
            <button
              style={styles.button}
              type="button"
              disabled={disableSubmitButton}
              onClick={() => this.startCall()}
              ref={ref => (this.submitButtonRef = ref)}
            >
              <Trans i18nKey="quickconnect__launch">
                Launch!
              </Trans>
            </button>
            {hasErrored === true && (
              <div className="form-error">
                <h3>
                  <Trans i18nKey="quickconnect__unableToConnect">
                    Unable to connect to Pod
                  </Trans>
                </h3>
                <ul>
                  <li>
                    <Trans i18nKey="quickconnect__checkNetwork">
                      Check your network connection.
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="quickconnect__refreshTryAgain">
                      Refresh this page and try again.
                    </Trans>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
        {view === "success" && (
          <div style={styles.parent}>
            <div style={styles.title}>
              <Trans i18nKey="quickconnect__worked">
                Success!
              </Trans>
            </div>
            {showShareButton && (
              <button
                style={styles.button}
                type="button"
                disabled={disableSubmitButton}
                onClick={() => this.startStream(true)}
                ref={ref => (this.submitButtonRef = ref)}>
                <Trans i18nKey="quickconnect__share">
                  Share
                </Trans>
              </button>
            )}
            <div style={styles.www}>
              <Trans i18nKey="quickconnect__worldWithoutWires">
                You are now sharing to the world without wires!
              </Trans>
            </div>
            <div style={styles.close}>
              <Trans i18nKey="quickconnect__stopSharing">
                To stop sharing, close this tab.
              </Trans>
            </div>
          </div>
        )}
        {view === "loading" && (
          <div style={styles.parent}>
            <h1 style={styles.connecting}>
              <Trans i18nKey="quickconnect__loading">
                Loading
              </Trans>
            </h1>
            <img
              style={styles.connectingImage}
              className="rotate"
              src={spinner}
              alt="Loading..."
            />
          </div>
        )}
        {view === "connecting" && (
          <div style={styles.parent}>
            <h1 style={styles.connecting}>
              <Trans i18nKey="quickconnect__connecting">
                Connecting
              </Trans>
            </h1>
            <img
              style={styles.connectingImage}
              className="rotate"
              src={spinner}
              alt="Connecting..."
            />
          </div>
        )}
      </div>
    );
  }
};

export default withTranslation(namespace)(InBrowserLaunch);